'use strict';

var base = require('../product/base');
var focusHelper = require('../components/focus');


/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */

function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem
        && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append(
                '<div class="add-to-cart-messages mt-5"></div>'
            );
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
            + response.message
            + '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-12 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.miniCart-wrap').empty();
            $('.miniCart-wrap').hide('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<p class="line-item-attributes">' + message + '</p>';
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal.formatted);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }
        $('.uuid-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(item.renderedPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });
}

$(document).ready(function () {
    $('.precoProduto').each(function () {
        var priceContent = $(this).attr('content');
        var formattedPrice = priceContent.replace('.', ',');
        $(this).text('R$' + formattedPrice);
    });
});

$(document).on('click', '.quantity-form-tile .js-decreaseAmountCart', function () {
    var $input = $(this).parent().find('input');
    var count = parseInt($input.val()) - 1;
    $input.val(count);
    $input.change();
    return false;
});

$(document).on('click', '.quantity-form-tile .js-increaseAmountCart', function () {
    var $input = $(this).parent().find('input');
    var newValue = parseInt($input.val()) + 1;

    if ($input.hasClass('js-qnt-minicart')) {
        if (newValue === 1) {
            $input.val(newValue + ' unidade');
        } else {
            $input.val(newValue + ' unidades');
        }
    }else{
        $input.val(newValue);
    }

    $input.change();
    return false;
});

//add to cart trigger
$('body').on('addToCart:event', function (event, element) {
    // console.log("🚀 ~ addToCart trigger call event: ")
    var addToCartUrl;
    $('body').trigger('product:beforeAddToCart', element);
    var pid = $(element).data('pid');
    var pidsObj = $(element).data('pidsobj');
    var quantity = 1;
    var form;

    if(pid){
        form = {
            pid: pid,
            quantity: quantity
        };
    } else if(pidsObj){
        form = {
            pidsObj: JSON.stringify(pidsObj)
        };
    }

    addToCartUrl = $('.add-to-cart-url').val();

    var self = element;
    $(self).addClass("is__loading");

    $(element).trigger('updateAddToCartFormData', form);
    if (addToCartUrl) {
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: form,
            success: function (data) {
                //datalayer trigger add_to_cart
                $('body').trigger('dataLayerPush:event', data.add_to_cart);

                handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);

                if(pid){
                    $('.quantity[data-pid="' + pid + '"]').val(quantity);
                    $('.quantity[data-pid="' + pid + '"]').attr('data-uuid', data.pliUUID);
                } else {
                    var newQuantity = Number($('.quantity[data-pid="' + pidsObj[0].pid + '"]').val())+1
                    $('.quantity[data-pid="' + pidsObj[0].pid + '"]').val(newQuantity);
                    $('.quantity[data-pid="' + pidsObj[0].pid + '"]').attr('data-uuid', data.pliUUID);
                }

                $(self).data('pre-select-qty', quantity);

                $('body').trigger('cart:update', data);

                $(self).removeClass("is__loading");
                $.spinner().stop();
                // change visible action button
                if (!$(self).hasClass('js-pdp-add')) {
                    $(self).addClass('d-none');
                }
                $(self).closest('.quantityAndShop').find('div.qtdContainer.productCard').removeClass('d-none');

                if ($(window).width() <= 992) {
                    $('body').trigger('miniCartOpenMobile:event');
                }

            },
            error: function () {
                $(self).removeClass("is__loading");
                $.spinner().stop();
            }
        });
    }
});

module.exports = {
    //update qtd
    updateQtd: function () {
        $('body').on('change', '.js-quantity-form-tile > .quantity', function (e) {
            var preSelectQty = $(this).data('pre-select-qty');
            var quantity = $(this).val();

            if (/unidade(s)?/.test(quantity)) {
                quantity = quantity.replace(/unidade(s)?/, '').trim();
                quantity = parseInt(quantity, 10);
            }
            if (isNaN(quantity)) {
                quantity = 0;
            }

            var productID = $(this).data('pid');
            var url = $(this).data('action');
            var uuid = $(this).attr('data-uuid');
            var self = this;
            var parent = $(this).parents('.quantity-form-tile');

            $(parent).addClass('is__loading');

            if(quantity==='0') {
                //remove
                e.preventDefault();
                // verifica se é um produto da vitrine ou do carrinho
                if ($(self).parents('.productBox').length === 1) {
                    $(self).parents('.productBox').find('.remove-product').trigger('click');
                    $('body').trigger('cart:beforeUpdate');

                    return;
                }

                var url = $(this).data('remove');
                var urlParams = {
                    pid: productID,
                    uuid: uuid
                };

                url = appendToUrl(url, urlParams);

                $('body').trigger('cart:beforeUpdate');
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        //datalayer trigger remove_from_cart
                        $('body').trigger('dataLayerPush:event', data.remove_from_cart);

                        // change visible action button
                        $(self).closest('.quantityAndShop').find('div.qtdContainer.productCard').addClass('d-none');
                        $(self).closest('.quantityAndShop').find('.quickBuyBtn.add-to-cart-carousel').removeClass('d-none');

                        if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                            for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                                $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                            }
                        }
                        $('.uuid-' + uuid).remove();
                        if (!data.basket.hasBonusProduct) {
                            $('.bonus-product').remove();
                        }
                        $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                        updateCartTotals(data.basket);
                        updateApproachingDiscounts(data.basket.approachingDiscounts);
                        $('body').trigger('setShippingMethodSelection', data.basket);
                        validateBasket(data.basket);

                        $('body').trigger('cart:update', data);

                        // gamificacao carrinho uptade
                        console.log(data.miniCartTagsRender);
                        $('.minicart-tags').empty().append(data.miniCartTagsRender);

                        $(parent).removeClass('is__loading');

                        $('body').trigger('miniCartCloseMobile:event');
                    },
                    error: function (err) {
                        $(parent).removeClass('is__loading');
                    }
                });
            } else {
                //uptade
                var urlParams = {
                    pid: productID,
                    quantity: quantity,
                    uuid: uuid
                };
                url = appendToUrl(url, urlParams);

                $('body').trigger('cart:beforeUpdate');

                $.ajax({
                    url: url,
                    type: 'get',
                    context: this,
                    dataType: 'json',
                    success: function (data) {
                        //datalayer trigger add_to_cart
                        $('body').trigger('dataLayerPush:event', data.add_to_cart);

                        $('body').trigger('product:afterAddToCart', data);

                        //$('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                        var $quantityInput = $('.quantity[data-uuid="' + uuid + '"]');
                        $quantityInput.val(quantity);

                        var $qntInputsMiniCart = $('.quantity[data-uuid="' + uuid + '"].js-qnt-minicart');
                        if ($qntInputsMiniCart.length > 0) {
                            console.log("🚀 ~ quantity:", quantity)
                            if (quantity == 1) {
                                $qntInputsMiniCart.siblings('.btn-trash').show();
                                $qntInputsMiniCart.siblings('.btn-decrease').hide();
                                $qntInputsMiniCart.val(quantity + ' unidade');
                            } else if(quantity == 0) {
                                console.log("🚀 ~ $(this).$('.remove-product').click()")
                                $('.remove-btn[data-pid="' + productID + '"]').click();
                            } else {
                                $qntInputsMiniCart.siblings('.btn-decrease').show();
                                $qntInputsMiniCart.siblings('.btn-trash').hide();
                                $qntInputsMiniCart.val(quantity + ' unidades');
                            }
                        }

                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        updateCartTotals(data);
                        updateApproachingDiscounts(data.approachingDiscounts);
                        updateAvailability(data, uuid);
                        validateBasket(data);
                        $(this).data('pre-select-qty', quantity);

                        $('body').trigger('cart:update', data);

                        // gamificacao carrinho uptade
                        console.log(data.miniCartTagsRender);
                        $('.minicart-tags').empty().append(data.miniCartTagsRender);

                        $(parent).removeClass('is__loading');

                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification(err.responseJSON.errorMessage);
                            $(this).val(parseInt(preSelectQty, 10));
                            $(parent).removeClass('is__loading');
                        }
                    }
                });
            }

            // $('.minicart').trigger('count:update', quantity);

        });
    },

    //add to cart home carousel
    addToCart: function () {
        $(document).on('click', 'button.add-to-cart-carousel', function () {

            // teste B
            var defaultCep = $('input[name="defaultCep"]').val();

            if(defaultCep === 'false') {
                $('body').trigger('addToCart:event', this);
            } else {
                $('#cepModal').modal('show')
                var pid = $(this).data('pid');
                setTimeout(() => {
                    $('#cepProductId').val(pid);
                }, 100);
            }
        });
    },

    //add to cart search
    searchAddToCart: function () {
        $(document).on('click', '.js-search-addToCard', function () {
            var addToCartUrl;
            $('body').trigger('product:beforeAddToCart', this);
            var pid = $(this).data('pid');
            var addToCartUrl = $(this).data('url');
            var quantity = 1;

            var form = {
                pid: pid,
                quantity: quantity
            };

            var self = this;
            $(self).addClass("is__loading");

            $(this).trigger('updateAddToCartFormData', form);

            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        //datalayer trigger add_to_cart
                        $('body').trigger('dataLayerPush:event', data.add_to_cart);

                        handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);

                        $('.quantity[data-pid="' + pid + '"]').val(quantity);
                        $('.quantity[data-pid="' + pid + '"]').attr('data-uuid', data.pliUUID);
                        $('body').trigger('cart:update', data);

                        $(self).removeClass("is__loading");

                    },
                    error: function () {
                        $(self).removeClass("is__loading");
                        $.spinner().stop();
                    }
                });
            }
        });
    }

};
